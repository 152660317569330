type CMSWrapperProps = {
  id: string
  content_type_id: string
}

// The `id` prop is required by the CMS Spyglass extension.
// The `data-cs-override-id` prop is required by Contentsquare.
// These props should be spread into the CMS component's wrapper/parent element.
export const getCMSWrapperProps = ({
  id,
  content_type_id,
}: CMSWrapperProps) => {
  return {
    id: `cms-${content_type_id}-${id}`,
    'data-cs-override-id': '',
  }
}
