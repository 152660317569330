import { apiPost } from '@/utils/dataFetching'
import {
  type Content,
  type CMSCatNavData,
  type CMSPageData,
  type StoresCarouselData,
  type CMSProductShelfData,
} from '@/components/CMS/types'
import { type QueryFunctionContext } from '@tanstack/react-query'
import { isOnServer } from '@shared/constants/util'
import { type StoreParams } from '@/utils/dataFetching/storeParams'
import {
  type getAddressParamsForCMS,
  createFetchCMSDataConfig,
  toCategory,
} from '@/services/CMS/utils'
import { type ShoppingCategories } from '@/services/ShoppingCategories/types'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'
import { type ProgressiveRevealShelfQueryKey } from '@/services/CMS/queries'

export type CMSQueryKey = Readonly<
  [
    `CMS_${string}`,
    {
      slug?: string
      storeParams: StoreParams | undefined
      addressParams: ReturnType<typeof getAddressParamsForCMS> | undefined
    }
  ]
>

export type CMSDataQueryFunctionContext = QueryFunctionContext<CMSQueryKey>

export const fetchCMSData = (context: CMSDataQueryFunctionContext) => {
  return apiPost<CMSPageData>({
    config: {
      ...createFetchCMSDataConfig({
        context,
        isOnServer: isOnServer(),
      }),
      signal: context.signal,
    },
    context,
    fetcherName: 'fetchCMSData',
  })
}

export const fetchDeferredHydration = ({
  url,
  headers,
  body,
}: {
  url: string
  headers: Record<string, string>
  body: unknown
}) => {
  // url from hydration callback object does not include gateway path
  const fullUrl = `/cms${url}`
  return apiPost<Content>({
    fetcherName: 'fetchDeferredHydration',
    config: {
      url: fullUrl,
      headers,
      data: body,
    },
  })
}

export const fetchCMSCatNavData = async (
  context: CMSDataQueryFunctionContext
): Promise<ShoppingCategories> => {
  const response = await apiPost<CMSCatNavData>({
    config: createFetchCMSDataConfig({
      context,
      isOnServer: isOnServer(),
      enableDeferredHydration: false,
      contentAlias: 'web-category-nav',
    }),
    context,
    fetcherName: 'fetchCMSCatNavData',
  })
  const { id: spyglassID } = getCMSWrapperProps({
    content_type_id: response.content_type_id,
    id: response.id,
  })
  return {
    category: response.data.category_navs?.map(toCategory) ?? [],
    featured_categories: response.data.featured_navs?.map(toCategory) ?? [],
    spyglassID,
  }
}

export const fetchProgressiveRevealShelfData = async (
  context: QueryFunctionContext<ProgressiveRevealShelfQueryKey>
) => {
  const [, params] = context.queryKey ?? []
  const { user_id, ...onlyStoreParams } = params.storeParams
  const response = await apiPost<CMSProductShelfData>({
    fetcherName: 'fetchProgressiveRevealShelfData',
    config: {
      url: 'cms/v1/content-alias/search-progressive-reveal',
      data: {
        ...onlyStoreParams,
        user_id: params.userId,
        input_variables: { product_id: params.productId },
        platform: 'web',
      },
    },
  })

  return Array.isArray(response.data?.items) && response.data.items.length > 6
    ? { ...response, shelfProductId: params.productId }
    : null
}

export const fetchOrderPlacedStoreCarousel = async (
  context: CMSDataQueryFunctionContext
): Promise<StoresCarouselData> => {
  const response = await apiPost<StoresCarouselData>({
    fetcherName: 'fetchOrderPlacedStoreCarousel',
    config: createFetchCMSDataConfig({
      context,
      isOnServer: false,
      enableDeferredHydration: true,
      contentAlias: 'order-placed-store-carousel',
    }),
    context,
  })
  return response
}
