import debounce from 'lodash/debounce'
import { type Product } from '@/services/Product/types'

export const criteoApi = (url: string) => {
  // Axios doesn't have a "no cors" mode so we can
  // use browser fetch since this is called on the client
  return fetch(url, { mode: 'no-cors' }).catch(() => {
    // Do nothing; ad blocker probably enabled
  })
}

// Due to bug with downshift dropdown, we need to debounce this call
export const criteoLoadBeacon = debounce((url: string) => criteoApi(url), 1000)

export const criteoViewBeacon = (product: Product) => {
  if (product.featured && product.beacons.onViewBeacon) {
    criteoApi(product.beacons.onViewBeacon)
  }
}

export const criteoClickBeacon = (product: Product) => {
  if (product.featured && product.beacons.onClickBeacon) {
    criteoApi(product.beacons.onClickBeacon)
  }
}

export const criteoLoadBeaconForProducts = (onLoadBeacons: string[]) => {
  if (!onLoadBeacons.length) return
  onLoadBeacons.forEach((onLoadBeacon) => criteoApi(onLoadBeacon))
}
